import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import { baseURL } from "../utils/constants";

const Register = () => {
  const navigate = useNavigate();

  const validationSchema = yup.object().shape({
    email: yup
      .string("Skriv inn epost")
      .email("Skriv inn en gyldig epost")
      .required("Epost er påkrevd")
      .max(255),
    password: yup
      .string("Skriv inn passord")
      .required("Passord er påkrevd")
      .max(255),
    confirmPassword: yup
      .string()
      .required([yup.ref("password"), !null], "Passordene må være like"),
    policy: yup.boolean().oneOf([true], "Du må godta de 10 bud"),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirmPassword: "",
      policy: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const url = new URL("/api/auth/register", baseURL)
      fetch(url.href, {
        method: "POST",
        headers: { "Content-Type": "application/json"},
        body: JSON.stringify({
          email: values.email,
          password: values.password,
        })
      }).then((response) => {
        if (response.ok) {
          toast.success("User registered, ask someone in the board to enable it")
          navigate("/login", { replace: true });
        } else {
            response.text().then((text) => {
              toast.error(text == "" ? "Something happened" : text)
            });
         }
      });
      return 0;
    }
  });

  return (
    <>
      <Helmet>
        <title>Få Tilgang · Adminpanel</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "center",
        }}
      >
        <Container maxWidth="sm">
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ mb: 3 }}>
              <Typography color="textPrimary" variant="h2">
                Få tilgang til panelet
              </Typography>
            </Box>
            <TextField
              error={Boolean(formik.touched.email && formik.errors.email)}
              fullWidth
              helperText={formik.touched.email && formik.errors.email}
              label="Epost-adresse"
              margin="normal"
              name="email"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="email"
              value={formik.values.email}
              variant="outlined"
            />
            <TextField
              error={Boolean(formik.touched.password && formik.errors.password)}
              fullWidth
              helperText={formik.touched.password && formik.errors.password}
              label="Passord"
              margin="normal"
              name="password"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="password"
              value={formik.values.password}
              variant="outlined"
            />
            <TextField
              error={Boolean(
                formik.touched.confirmPassword && formik.errors.confirmPassword
              )}
              fullWidth
              helperText={
                formik.touched.confirmPassword && formik.errors.confirmPassword
              }
              label="Gjenta passord"
              margin="normal"
              name="confirmPassword"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="password"
              value={formik.values.confirmPassword}
              variant="outlined"
            />
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                ml: -1,
              }}
            >
              <Checkbox
                checked={formik.values.policy}
                name="policy"
                onChange={formik.handleChange}
              />
              <Typography color="textSecondary" variant="body1">
                Jeg har lest{" "}
                <Link
                  color="primary"
                  component={RouterLink}
                  to="#"
                  underline="always"
                >
                  De 10 bud
                </Link>
              </Typography>
            </Box>
            {Boolean(formik.touched.policy && formik.errors.policy) && (
              <FormHelperText error>{formik.errors.policy}</FormHelperText>
            )}
            <Box sx={{ py: 2 }}>
              <Button
                color="primary"
                disabled={!formik.isValid || formik.isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Be om tilgang
              </Button>
            </Box>
            <Typography
              color="textSecondary"
              variant="body2"
              marginBottom="10px"
            >
              Når dette skjemaet er fylt ut kan du snakke med noen i IT komiteen
              for å få tilgang.
            </Typography>
            <Typography color="textSecondary" variant="body1">
              Har du allerede tilgang?{" "}
              <Link component={RouterLink} to="/login" underline="hover">
                Logg inn
              </Link>
            </Typography>
          </form>
        </Container>
      </Box>
    </>
  );
};

export default Register;
