import { Divider, IconButton, List } from "@mui/material";
import { Box } from "@mui/system";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import NavItem from "../../NavItem";
import {
  BarChart,
  ChevronLeft,
  ChevronRight,
  Favorite,
  Group,
  Home,
  PersonAdd,
  Settings,
} from "@mui/icons-material";

/**
 * DashboardSidebar component is the sidebar for the adminpanel 
 */

//Items in the sidebar
const items = [
  {
    href: "/app/dashboard",
    icon: Home,
    title: "Dashboard",
  },
  {
    href: "/app/add-member",
    icon: PersonAdd,
    title: "Nytt medlem",
  },
  {
    href: "/app/members",
    icon: Group,
    title: "Medlemmer",
  },
  {
    href: "/app/users",
    icon: Favorite,
    title: "Familien",
  },
  {
    href: "",
    icon: BarChart,
    title: "Statistikk",
  },
  {
    href: "",
    icon: Settings,
    title: "Innstillinger",
  },
];

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const DashboardSidebar = ({ onMobileClose, openMobile }, props) => {
  const location = useLocation();
  const theme = useTheme();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box sc={{ p: 2 }}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
              {...props}
            />
          ))}
        </List>
      </Box>
    </Box>
  );

  return (
    <>
      <Drawer variant="permanent" open={openMobile} {...props}>
        <DrawerHeader>
          <IconButton onClick={onMobileClose}>
            {theme.direction === "rtl" ? <ChevronRight /> : <ChevronLeft />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        {content}
      </Drawer>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default DashboardSidebar;
