import { PersonAdd } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { orange } from "@mui/material/colors";

/**
 * AddMember component is a card that links to the add-member page
 * @param {*} props 
 * @returns 
 */
const AddMember = (props) => (
  <Card sx={{ height: "100%" }} {...props}>
    <CardActionArea href="/app/add-member">
      <CardContent>
        <Grid container spacing={2} sx={{ justifyContent: "space-between" }}>
          <Grid item>
            <Typography variant="h6">Regstrer nytt medlem</Typography>
          </Grid>
          <Grid item>
            <Avatar
              sx={{
                backgroundColor: orange[600],
                height: 56,
                width: 56,
              }}
            >
              <PersonAdd />
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </CardActionArea>
  </Card>
);

export default AddMember;
