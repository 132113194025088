import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Container, TextField, Button, Typography, Link } from "@mui/material";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import { Helmet } from "react-helmet";
import * as yup from "yup";
import useToken from "../utils/useToken";
import { baseURL } from "../utils/constants";
import { toast } from "react-toastify";
const Login = () => {
  const { setToken } = useToken();
  const navigate = useNavigate();
  const validationSchema = yup.object().shape({
    email: yup
      .string("Skriv inn epost")
      .email("Skriv inn en gyldig epost")
      .required("Epost er påkrevd")
      .max(255),
    password: yup
      .string("Skriv inn passord")
      .required("Passord er påkrevd")
      .max(255),
  });

  const formik = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      return fetch(`${baseURL}/auth/login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(values),
      })
        .then((response) => {
          if (response.ok) {
            response.text().then((token) => {
              setToken(token);
              navigate("/app/dashboard");
            });
          } else {
            toast.error("Feil brukernavn eller passord");
          }
        })
        .catch((error) => {toast.error("Check you Internet connection")});
    },
  });

  return (
    <>
      <Helmet>
        <title>Logg Inn · Adminpanel</title>
      </Helmet>

      <Box
        sx={{
          backgroundColor: "Background.default",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "center",
        }}
      >
        <Container maxWidth="sm">
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <img src="/ÅSS.svg" width="400px" alt="ÅSS Logo" />
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              error={Boolean(formik.touched.email && formik.errors.email)}
              fullWidth
              helperText={formik.touched.email && formik.errors.email}
              label="Epost-adresse"
              margin="normal"
              name="email"
              onChange={formik.handleChange}
              type="email"
              value={formik.values.email}
              variant="outlined"
            />
            <TextField
              error={formik.touched.password && Boolean(formik.errors.password)}
              fullWidth
              helperText={formik.touched.password && formik.errors.password}
              label="Passord"
              margin="normal"
              name="password"
              onChange={formik.handleChange}
              type="password"
              value={formik.values.password}
              variant="outlined"
            />
            <Box sx={{ py: 2 }}>
              <Button
                color="primary"
                disabled={formik.isSubmitting || !formik.isValid}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Logg inn
              </Button>
            </Box>
            <Typography color="textSecondary" variant="body1">
              Har du ikke tilgang?{" "}
              <Link component={RouterLink} to="/register" underline="hover">
                Få tilgang til panelet
              </Link>
            </Typography>
          </form>
        </Container>
      </Box>
    </>
  );
};

export default Login;
