import { Box } from "@mui/system";
import { Container, Grid } from "@mui/material";
import { Helmet } from "react-helmet";
import AddMember from "../components/dashboard/AddMember";

const Dashboard = () => {
  return (
    <>
      <Helmet>
        <title>Dashboard · Adminpanel</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "Background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <Grid container spacing={3}>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <AddMember />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Dashboard;
