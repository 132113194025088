import { Link as RouterLink } from "react-router-dom";
import { AppBar, Toolbar } from "@mui/material";

const MainNavbar = (props) => (
  <AppBar {...props}>
    <Toolbar sx={{ height: 64 }}>
      <RouterLink to="/">
        <img
          src="/ÅSS-black.svg"
          {...props}
          width="100px"
          alt="ÅSS Logo svart"
        />
      </RouterLink>
    </Toolbar>
  </AppBar>
);

export default MainNavbar;
