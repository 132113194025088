import React, { useCallback, useEffect, useMemo, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import UseToken from "../utils/useToken";
import { baseURL } from "../utils/constants";
import { Helmet } from "react-helmet";
import { Box } from "@mui/system";
import {
  Dialog,
  DialogActions,
  IconButton,
  Tooltip,
  DialogTitle,
  Button,
  tableCellClasses,
} from "@mui/material";
import { Edit, Print, Delete, UndoRounded } from "@mui/icons-material";
import * as Yup from "yup";
import { toast } from "react-toastify";

const MemberTable = () => {
  const { token } = UseToken();
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  //table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [deletingMember, setDeletingMember] = React.useState({});
  const [dialogTitle, setDialogTitle] = useState();
  const [validationErrors, setValidationErrors] = useState({});

  const removeDeleteMember = () => {
    setDeletingMember({});
  };

  const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
    if (!Object.keys(validationErrors).length) {
      exitEditingMode();
      const url = new URL(`/api/members/edit/${row.original.id}`, baseURL);
      const request = fetch(url, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });
      toast.promise(request, {
        pending: "Sending modified member",
        error: "Failed to send request",
      });
      request.then((response) => {
        if (response.ok) {
          toast.success("Member changed");
          data[row.index] = values;
          setData([...data]);
        } else {
          toast.error("Something went wrong");
        }
      });
    }
  };

  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

  const deleteMember = () => {
    const member = deletingMember;
    setDeletingMember({});
    const url = new URL(`/api/members/${member?.original.id}`, baseURL);
    const request = fetch(url, {
      method: "DELETE",
      headers: { Authorization: `Bearer ${token}` },
    });
    toast.promise(request, {
      pending: "Sending request to delete",
      error: "Failed to delete user",
    });
    request.then((response) => {
      if (response.ok) {
        toast.success("Succesfully deleted the user");
        data.splice(deletingMember.index, 1);
        setData([...data]);
      } else {
        toast.error("Could not delete user");
      }
    });
  };

  const sendPrintCard = (id) => {
    const url = new URL(`/api/print/member/${id}`, baseURL);
    const request = fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        volunteer: false,
      }),
    });
    toast.promise(request, {
      pending: "Sending print request",
      error: "Failed to send print request",
    });
    request.then((response) => {
      if (response.ok) {
        toast.success("Added to printer queue");
      } else {
        toast.error("Something went wrong");
      }
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!data.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      const filters = {};
      columnFilters.forEach((object) => {
        filters[object.id] = object.value;
      });

      const url = new URL("/api/members/search", baseURL);
      url.searchParams.set("page", `${pagination.pageIndex}`);
      url.searchParams.set("size", `${pagination.pageSize}`);
      url.searchParams.set("sorting", JSON.stringify(sorting ?? []));
      try {
        const response = await fetch(url.href, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(filters),
          method: "POST",
        });
        const json = await response.json();
        setData(json.content);
        setRowCount(json.totalElements);
      } catch (error) {
        setIsError(true);
        console.error(error);
        return;
      }
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnFilters, pagination.pageIndex, pagination.pageSize, sorting]);

  const validationSchema = Yup.object().shape({
    firstname: Yup.string().max(20).required("Fornavn er påkrevd"),
    lastname: Yup.string().max(20).required("Etternavn er påkrevd"),
    email: Yup.string().required("E-post er påkrevd"),
  });

  const getCommonEditTextFieldProps = useCallback(
    (cell) => {
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        onBlur: (event) => {
          validationSchema.fields[cell.column.id]
            .validate(event.target.value)
            .then((result) => {
              delete validationErrors[cell.id];
              setValidationErrors({ ...validationErrors });
            })
            .catch((error) => {
              setValidationErrors({
                ...validationErrors,
                [cell.id]: error.message,
              });
            });
        },
      };
    },
    [validationErrors]
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        enableColumnFilter: false,
        enableEditing: false,
        size: 89,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: "firstname",
        header: "Fornavn",
        size: 140,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: "lastname",
        header: "Etternavn",
        size: 140,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: "email",
        header: "Epost",
        size: 100,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          type: "email",
        }),
      },
    ],
    [getCommonEditTextFieldProps]
  );

  return (
    <>
      <Helmet>
        <title>Medlemmer · Adminpanel</title>
      </Helmet>
      <Dialog
        open={deletingMember?.id}
        onClose={removeDeleteMember}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogActions>
          <Button onClick={removeDeleteMember}>No</Button>
          <Button onClick={deleteMember}>Yes</Button>
        </DialogActions>
      </Dialog>

      <MaterialReactTable
        columns={columns}
        data={data}
        pageSizeOptions={[5, 10]}
        enableGlobalFilter={false}
        manualFiltering
        enableEditing
        manualPagination
        manualSorting
        onEditingRowSave={handleSaveRowEdits}
        onEditingRowCancel={handleCancelRowEdits}
        onColumnFiltersChange={setColumnFilters}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
        rowCount={rowCount}
        renderRowActions={({ row, table }) => (
          <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
            <Tooltip title="Rediger">
              <IconButton
                aria-label="edit"
                onClick={() => table.setEditingRow(row)}
              >
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip title="Print">
              <IconButton
                aria-label="print"
                onClick={() => sendPrintCard(row.original.id)}
              >
                <Print />
              </IconButton>
            </Tooltip>
            <Tooltip title="Slett">
              <IconButton
                aria-label="delete"
                onClick={() => {
                  setDialogTitle(
                    `Are you sure you want to delete ${row.original.firstname} ${row.original.lastname}`
                  );
                  setDeletingMember(row);
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        initialState={{
          showColumnFilters: true,
          columnOrder: [
            "id",
            "firstname",
            "lastname",
            "email",
            "mrt-row-actions",
          ],
        }}
        state={{
          columnFilters,
          isLoading,
          pagination,
          showAlertBanner: isError,
          showProgressBars: isRefetching,
          sorting,
        }}
      />
    </>
  );
};

export default MemberTable;
