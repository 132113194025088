import { Toolbar, IconButton, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import PropTypes from "prop-types";
import { Logout } from "@mui/icons-material";
import { Link as RouterLink } from "react-router-dom";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DashboardNavbar = ({ onMobileNavOpen, openMobile }, props) => {
  return (
    <AppBar position="fixed" open={openMobile} {...props}>
      <Toolbar sx={{ height: 64 }}>
        <IconButton
          onClick={onMobileNavOpen}
          edge="start"
          sx={{
            marginRight: "36px",
            paddingLeft: "12px",
            ...(openMobile && { display: "none" }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <RouterLink to="/">
          <img
            src="/ÅSS-black.svg"
            {...props}
            width="100px"
            alt="ÅSS Logo svart"
          />
        </RouterLink>
        <Box sx={{ flexGrow: 1 }} />
        <IconButton onClick= {() => {
          localStorage.removeItem('token');
          window.location.href = '/login';
        }}>
          <Logout />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

DashboardNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func,
};

export default DashboardNavbar;
