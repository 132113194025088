import { Navigate, Route, Routes } from "react-router";
import DashboardLayout from "./components/layouts/dashboard/DashboardLayout";
import MainLayout from "./components/layouts/main/MainLayout";
import AddMember from "./pages/AddMember";
import Dashboard from "./pages/Dashboard";

import Login from "./pages/Login";
import Register from "./pages/Register";
import Users from "./pages/Users";

import { AuthWrapper } from "./utils/useToken";
import MemberTable from "./pages/Members";

const ASSRoutes = () => {
  return (
    <Routes>
      <Route element={<MainLayout/>}>
        <Route path="/login" element={<Login/>}/>
        <Route path="/register" element={<Register/>}/>
        <Route path="/" element={<Navigate to="/app/dashboard"/>}/>
      </Route>
      <Route element={<AuthWrapper />}>
        <Route path="/app" element={<DashboardLayout />}>
          <Route path="dashboard" element={<Dashboard />}/>
          <Route path="add-member" element={<AddMember />}/>
          <Route path="members" element={<MemberTable />}/>
          <Route path="users" element={<Users/>}/>
        </Route>
      </Route>
    </Routes>
  );
};

export default ASSRoutes;
