import { useNavigate } from "react-router-dom";
import { useState } from 'react';
import { Helmet } from "react-helmet";
import {
  Container,
  TextField,
  Button,
  Typography,
  Checkbox,
} from "@mui/material";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import * as Yup from "yup";
import { baseURL } from "../utils/constants";
import { toast } from "react-toastify";

const AddMember = () => {
  const navigate = useNavigate();
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [volunteer, setVolunteer] = useState(false);
  const [printCard, setPrintCard] = useState(true);

  const onFormSubmit = (values) => {
    const request = fetch(`${baseURL}/members/add`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem("token") 
      },
      body: JSON.stringify({
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        printCard: values.printCard,
        volunteer: values.volunteer
      }),
    })
    
    toast.promise(request, {
      pending: "Submitting new member",
      success: "New member submitted",
      error: "Something went wrong"
    })
    return request.then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        navigate("/app/dashboard", { replace: true });
      })
      .catch(error => {
        console.error('There has been a problem with your fetch operation:', error);
      })
  };

  const validationSchema = Yup.object().shape({
              firstName: Yup.string()
                .max(20)
                .required("Fornavn er påkrevd"),
              lastName: Yup.string()
                .max(20)
                .required("Etternavn er påkrevd"),
                email: Yup.string().required('E-post er påkrevd'),
            })
  const formik = useFormik({
    initialValues: {
       firstName: "",
              lastName: "",
              printCard: true,
              volunteer: false
    },
    validationSchema: validationSchema,
    onSubmit:onFormSubmit
  })

  return (
    <>
      <Helmet>
        <title>Nytt Medlem · Adminpanel</title>
      </Helmet>

      <Box
        sx={{
          backgroundColor: "Background.default",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          py: 2,
        }}
      >
        <Container maxWidth="sm">
          <Typography sx={{ mb: 1 }} variant="h4">
            Registrer Nytt Medlem
          </Typography>
              <form onSubmit={formik.handleSubmit}>
                <TextField
                  error={Boolean(formik.touched.firstName && formik.errors.firstName)}
                  fullWidth
                  helperText={formik.touched.firstName && formik.errors.firstName}
                  label="Fornavn"
                  margin="normal"
                  name="firstName"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="text"
                  value={formik.values.firstName}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(formik.touched.lastName && formik.errors.lastName)}
                  fullWidth
                  helperText={formik.touched.lastName && formik.errors.lastName}
                  label="Etternavn"
                  margin="normal"
                  name="lastName"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="text"
                  value={formik.values.lastName}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(formik.touched.email && formik.errors.email)}
                  fullWidth
                  helperText={formik.touched.email && formik.errors.email}
                  label="E-postadresse"
                  margin="normal"
                  name="email"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="text"
                  value={formik.values.email}
                  variant="outlined"
                />
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <Typography color="textSecondary" variant="body1">
                    Frivillig?
                  </Typography>
                  <Checkbox
                    checked={formik.values.volunteer}
                    name="volunteer"
                    onChange={formik.handleChange}
                  />
                </Box>
                                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <Typography color="textSecondary" variant="body1">
                    Printe kort?
                  </Typography>
                  <Checkbox
                    checked={formik.values.printCard}
                    name="printCard"
                    onChange={formik.handleChange}
                  />
                </Box>
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                </Box>
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={formik.isSubmitting || !formik.isValid}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Registrer Medlem
                  </Button>
                </Box>
              </form>
        </Container>
      </Box>
    </>
  );
};

export default AddMember;
