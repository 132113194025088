import React, { useEffect, useMemo, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import CheckIcon from "@mui/icons-material/Check";
import { Box } from "@mui/system";
import { Dialog, DialogActions, IconButton, Tooltip, DialogTitle, Button } from "@mui/material";
import { Edit, Print, Delete, UndoRounded } from "@mui/icons-material";
import UseToken from "../utils/useToken";
import { baseURL } from "../utils/constants";
import { Helmet } from "react-helmet";
import {toast} from "react-toastify"

export default function UserTable() {
  const { token } = UseToken();
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  //table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

const sendPrintCard = (id) => {
    const url = new URL(`/api/print/member/${id}`, baseURL);
    const request = fetch(url, {
      method: "POST",
      headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
      body: JSON.stringify({
        "volunteer": true
      })
    });
    toast.promise(request, {
      pending: "Sending print request",
      error: "Failed to send print request"
    })
    request.then((response) => {
      if (response.ok) {
        toast.success("Added to printer queue")
        }
      else {
        toast.error("Something went wrong")
      }
    })
  };

function DeactivateUser(id) {
  const url = new URL(`/api/users/deactivate/${id}`, baseURL);
  const response = fetch(url, {
  headers: { Authorization: `Bearer ${token}` },method: "POST",})
  return !!(response.then((response) => {return response.ok}));
}
function ActivateUser(id) {
  const url = new URL(`/api/users/activate/${id}`, baseURL);
  const response = fetch(url, {
  headers: { Authorization: `Bearer ${token}` },method: "POST",});
  return !!(response.then((response) => {return response.ok}));
}

  useEffect(() => {
    const fetchData = async () => {
      if (!data.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      const filters = {};
      columnFilters.forEach((object) => {
        filters[object.id.replace("member.", "")] = object.value;
      });

      const url = new URL("/api/users/search", baseURL);
      url.searchParams.set("page", `${pagination.pageIndex}`);
      url.searchParams.set("size", `${pagination.pageSize}`);
      url.searchParams.set("sorting", JSON.stringify(sorting ?? []));
      try {
        const response = await fetch(url.href, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(filters),
          method: "POST",
        });
        const json = await response.json();
        setData(json.content);
        setRowCount(json.totalElements);
      } catch (error) {
        setIsError(true);
        console.error(error);
        return;
      }
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting
  ]);
  const columns = useMemo(
    () => [
      { accessorKey: "id", header: "ID", enableColumnFilter: false },
      { accessorKey: "member.firstname", header: "Fornavn" },
      { accessorKey: "member.lastname", header: "Etternavn" },
      { accessorKey: "member.email", header: "Epost" },
      {
        accessorKey: "enabled",
        header: "Enabled",
        Cell: ({ cell }) =>
          cell.getValue() ? <CheckIcon /> : <DoDisturbOnIcon />,
        filterVariant: "select",
        filterSelectOptions: [
          { text: "Enabled", value: "true" },
          { text: "Disabled", value: "false" },
        ],
      },
    ],
    []
  );

  return (
    <>
      <Helmet>
        <title>Brukere · Adminpanel</title>
      </Helmet>
      <MaterialReactTable
        columns={columns}
        data={data}
        pageSizeOptions={[5, 10]}
        enableGlobalFilter={false}
        enableRowSelection
        checkboxSelection
        manualPagination
        manualSorting
        manualFiltering
        enableEditing
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
        renderTopToolbarCustomActions={({ table }) => {
          const handleDeactivate = () => {            
            table.getSelectedRowModel().flatRows.forEach((row) => {
             if (DeactivateUser(row.original.id)) {
                data[row.id].enabled = false;
              }
            })
            setData([...data])
          };
          const handleActivate = () => {
            table.getSelectedRowModel().flatRows.forEach((row) => {
              if (ActivateUser(row.original.id)) {
                data[row.id].enabled = true;
              }
            });
            setData([...data])
          };
          return (
            <div style={{ display: "flex", gap: "0.5rem" }}>
              <Button
                color="success"
                disabled={
                  !table.getIsSomeRowsSelected() &&
                  !table.getIsAllRowsSelected()
                }
                onClick={handleActivate}
                variant="contained"
              >
                Activate
              </Button>
              <Button
                color="error"
                disabled={
                  !table.getIsSomeRowsSelected() &&
                  !table.getIsAllRowsSelected()
                }
                onClick={handleDeactivate}
                variant="contained"
              >
                Deactivate
              </Button>
            </div>
          );
        }}
        rowCount={rowCount}
        initialState={{
          showColumnFilters: true,
            columnOrder: [
            "mrt-row-select",
            "id",
            "member.firstname",
            "member.lastname",
            "member.email",
            "enabled",
            "mrt-row-actions",
          ]
        }}
         renderRowActions={({ row, table }) => (
          <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
            <Tooltip title="Rediger">
              <IconButton aria-label="edit" >
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip title="Print">
              <IconButton
                aria-label="print"
                onClick={() => sendPrintCard(row.original.member.id)}
              >
                <Print />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        state={{
          columnFilters,
          isLoading,
          pagination,
          showAlertBanner: isError,
          showProgressBars: isRefetching,
          sorting,
        }}
      />
    </>
  );
}
