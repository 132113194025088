import { ThemeProvider } from '@mui/material/styles';
import { StyledEngineProvider } from "@mui/styled-engine";
import GlobalStyles from './components/GlobalStyles';
import theme from './theme';
import ASSRoutes from './routes';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <ASSRoutes/>
          <ToastContainer position="top-right"/>
        </ThemeProvider>
      </StyledEngineProvider>
  );
};

export default App;
